import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import qrcode from "@/store/qrcode";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showModalDataForm: false,
    context: undefined,
    hideNoFilled: false,
    allFilled: false,
    imgLogoDefault: "/img/my-life-click-logo.jpeg",
    snackbarLoading: {
      status: false,
      message: "Atualizando aguarde...",
    },
    snackbarSuccess: {
      status: false,
      message: "Atualizado com sucesso!",
    },
    snackbarError: {
      status: false,
      message: "Erro tente novamente.",
    },
  },
  getters: {
    getURLBase: () => process.env.NODE_ENV === 'production' ? 'http://sigabrad.com.br/' : 'http://beta.sigabrad.com.br/'
  },
  mutations: {
    setShowModalDataForm(state, payload) {
      state.showModalDataForm = payload;
    },
    setContext(state, payload) {
      state.context = payload;
    },
    setHideNoFilled(state, payload) {
      state.hideNoFilled = payload;
    },
    setAllFilled(state, payload) {
      state.allFilled = payload;
    },
    setSnackbarLoading(state, { status, message }) {
      state.snackbarLoading.status = status;
      state.snackbarLoading.message = message;
    },
    setSnackbarSuccess(state, { status, message }) {
      state.snackbarSuccess.status = status;
      state.snackbarSuccess.message = message;
    },
    setSnackbarError(state, { status, message }) {
      state.snackbarError.status = status;
      state.snackbarError.message = message;
    },
  },
  actions: {},
  modules: {
    auth,
    qrcode,
  },
  plugins: [vuexLocal.plugin],
});
