import Vue from "vue";
import { toPT_BRCapitalize } from "@/util";

Vue.filter("toLocaleDate", function (value) {
  return !value || !/\d\d\d\d-\d\d-\d\d/.test(value)
    ? new Date(value).toJSON().split("T")[0].split("-").reverse().join("/")
    : value.split("-").reverse().join("/");
});

Vue.filter("toAge", function (value) {
  return !value || !/\d\d\d\d-\d\d-\d\d/.test(value)
    ? new Date().getFullYear() - new Date(value).getFullYear()
    : new Date().getFullYear() - Number(value.split("-")[0]);
});

Vue.filter("toPT_BRCapitalize", function (value) {
  return toPT_BRCapitalize(value);
});
