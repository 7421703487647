<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>
<script>
export default {
  metaInfo: {
    title: "My Life Click",
    titleTemplate: "%s",
  },
};
</script>
<style lang="scss">
@import "./assets/custom";
</style>
