import api, {authHeader} from "@/store/api";

const qrcode = {
  namespaced: true,
  state: () => ({
    data: [],
    dataQRCode: {},
    statusDataImgQRCode: {},
    statusData: 0,
    statusDataQRCode: 0,
    error: null,
  }),
  mutations: {
    setData: (state, payload) => (state.data = payload),
    setDataQRCode: (state, payload) => (state.dataQRCode = payload),
    setStatusDataImgQRCode: (state, payload) => (state.statusDataImgQRCode = payload),
    setStatusData: (state, payload) => (state.statusData = payload),
    setStatusDataQRCode: (state, payload) => (state.statusDataQRCode = payload),
    setError: (state, payload) => (state.error = payload),
  },
  actions: {
    getImgQRCode({ commit }, {type, uid, callbackError}) {
      return new Promise((resolve, reject) => {
        commit('setStatusDataImgQRCode', 1)
        commit('setError', null)
        api
          .get(`api/qrcode/${type}/${uid}`)
          .then((result) => {
            commit('setStatusDataImgQRCode', 0)
            resolve(result.data);
          })
          .catch((err) => {
            console.error(err);
            callbackError && callbackError(err);
            commit('setStatusDataImgQRCode', 2)
            commit('setError', err && err.response)
            reject(err && err.response);
          })
      })
    },
    getGenerate({ commit }, {quantity, type, callbackError}) {
      return new Promise((resolve, reject) => {
        commit('setStatusDataQRCode', 1)
        commit('setError', null)
        api
          .get(`api/qrcode/generate/${type}/${quantity}/default`)
          .then((result) => {
            commit('setStatusDataQRCode', 0)
            resolve(result.data.content);
          })
          .catch((err) => {
            console.error(err);
            callbackError && callbackError(err);
            commit('setStatusDataQRCode', 2)
            commit('setError', err && err.response)
            reject(err && err.response);
          })
      })
    },
    getOneByStatus({ commit }, { status, callbackError }) {
      return new Promise((resolve, reject) => {
        commit('setStatusDataQRCode', 1)
        commit('setError', null)
        api
          .get(`api/qrcode/generate/status/${status}`)
          .then((result) => {
            commit('setStatusDataQRCode', 0)
            commit("setDataQRCode", result.data.content[0]);
            resolve(result.data.content[0])
          })
          .catch((err) => {
            console.error(err);
            callbackError && callbackError(err);
            commit('setStatusDataQRCode', 2)
            commit('setError', err && err.response)
            reject(err && err.response);
          })
      });
    },
    getData({ commit }, { callbackError }) {
      return new Promise((resolve, reject) => {
        commit('setStatusData', 1)
        commit('setError', null)
        api
          .get(`api/qrcode/generates`)
          .then((result) => {
            commit('setStatusData', 0)
            commit("setData", result.data.content);
            resolve(result.data.content);
          })
          .catch((err) => {
            console.error(err);
            callbackError && callbackError(err);
            commit('setStatusData', 2)
            commit('setError', err && err.response)
            reject(err && err.response);
          })
      })
    },
    getTypes({ commit }, { callbackSuccess, callbackError }) {
      return new Promise((resolve, reject) => {
        commit('setStatusData', 1)
        commit('setError', null)
        api
          .get(`api/qrcode/types`)
          .then((result) => {
            commit('setStatusData', 0)
            resolve(result.data.content);
            callbackSuccess && callbackSuccess(result.data.content)
          })
          .catch((err) => {
            console.error(err);
            callbackError && callbackError(err);
            commit('setStatusData', 2)
            commit('setError', err && err.response)
            reject(err && err.response);
          })
      })
    },
    resetUser({rootState},{type, uid}) {
      return new Promise((resolve, reject) => {
        api
          .post(`/api/user/${type}/reset?id=${uid}`, {}, { headers: authHeader(rootState.auth.user.token) })
          .then((result) => {
            resolve(result.data.content);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    getDataUser(params,{type, uid}) {
      return new Promise((resolve, reject) => {
        api
          .get(`/api/${type}/info?id=${uid}`)
          .then((result) => {
            resolve(result.data.content);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
      
      
      // Modelo QR Code Status
      //
      // arquivo
      // fimProcesso
      // id
      // inicioProcesso
      // logErro
      // loteStatus
      // quantidade
      // status
      // tempo
      // tipo
      // uid
      
    },
  },
};

export default qrcode;
