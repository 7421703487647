<template>
  <div class="login-form">
    <v-row no-gutters>
      <v-col>
        <v-card class="rounde-lg">
          <v-card-text>
            <v-row no-gutters class="d-flex justify-center text-center">
              <v-col cols="12" class="d-flex justify-center">
                <v-img
                  content-class="d-flex"
                  :src="getLogo(2) || $store.state.imgLogoDefault"
                  max-width="180"
                />
              </v-col>
              <v-col cols="5" class="pa-2 pb-3">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <h1 class="title">Login</h1>
                <div class="mt-3">
                  <small>acesso ao backoffice administrativo</small>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent.stop="onSubmit">
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="form.email"
                    type="email"
                    label="e-mail"
                    prepend-icon="mdi-at"
                    autocomplete="off"
                    required
                    :error-messages="errors"
                    :readonly="showLoading"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required"
                >
                  <v-text-field
                    v-model="form.password"
                    label="senha"
                    prepend-icon="mdi-key-variant"
                    autocomplete="new-password"
                    required
                    :error-messages="errors"
                    :readonly="showLoading"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    class="mt-3"
                    @click:append="showPassword = !showPassword"
                    @keypress="$refs.observer.validate()"
                  />
                </validation-provider>
                <!--                <v-layout justify-end>-->
                <!--                  <router-link to="/recuperar-senha"-->
                <!--                    >recuperar senha</router-link-->
                <!--                  >-->
                <!--                </v-layout>-->
                <div class="controlls d-flex centerify mt-6">
                  <v-btn
                    v-if="!showLoading"
                    type="submit"
                    color="primary"
                    :disabled="invalid"
                    class="mt-6 px-6"
                  >
                    Login
                  </v-btn>
                  <div v-else class="d-flex centerify flex-column">
                    <v-progress-circular
                      :width="2"
                      size="20"
                      color="primary"
                      indeterminate
                      class="mb-3"
                    />
                    <div>conectando</div>
                  </div>
                </div>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
        <v-alert
          color="red"
          outlined
          dark
          type="error"
          :value="showError"
          transition="scroll-y-reverse-transition"
          class="alert-error py-3 mt-3"
        >
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("email", {
  ...email,
  message: "e-mail inválido",
});

extend("required", {
  ...required,
  message: "obrigatório",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    onRequest: {
      type: Function,
      default: async () => {},
    },
    onResponse: {
      type: Function,
      default: async () => {},
    },
    imgLogo: {
      imgLogo: {
        type: String,
      },
    },
  },
  data: () => ({
    showError: false,
    showLoading: false,
    showPassword: false,
    form: {
      email: "",
      password: "",
    },
    errorMessage: "",
  }),
  computed: {
    getLogo() {
      return (num) => {
        return !this.imgLogo ? null : this.imgLogo + "/logo_" + num + ".jpg";
      };
    },
  },
  mounted() {
    // TODO definir como será o controle para que cada cliente tenha um acesso de login separado, tipo: /nomeDoCliente/login
    // enquanto isso não é definido as linhas abaixo seguem comentadas, para não resetar o cliente que já estava carregado.
    // reseta o cliente
    // this.$store.commit("cliente/setData", {});
  },
  methods: {
    async onSubmit() {
      this.$refs.observer.validate();

      this.showLoading = true;

      this.showError = false;

      const response = await this.onRequest(this.form);

      this.showLoading = false;

      this.onResponse(response, (error) => {
        this.showError = true;
        this.errorMessage = error;
      });
    },
  },
};
</script>

<style lang="scss">
.login-form {
  position: relative;
  min-height: 296px;
  max-width: 300px;

  .v-card {
    min-height: 262px;
  }

  .v-alert {
    position: relative;
    width: 100%;
  }
}
</style>
