<template>
  <div class="layout-default">
    <v-app v-if="$store.state.auth.user.token">
      <layout-dektop v-if="$vuetify.breakpoint.lgAndUp" />
      <layout-mobile v-if="$vuetify.breakpoint.mdAndDown" />
      <snackbar-messages />
    </v-app>
  </div>
</template>

<script>
import MixinGlobal from "@/mixins";
import LayoutDektop from "./desktop";
import LayoutMobile from "./mobile";
import SnackbarMessages from "@/components/snackbars/snackbar-messages";
export default {
  mixins: [MixinGlobal],
  components: { SnackbarMessages, LayoutDektop, LayoutMobile },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //console.log(to, from, (to.path.includes("pet") && _this.$store.state.context !== "pet"), (!to.path.includes("pet") && _this.$store.state.context !== "pessoa"));
      if (!vm.$store.state.auth.user.token) {
        return vm.$router.push("/login");
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit("setShowModalDataForm", false);
    next();
  },
  computed: {
    // auth() {
    //   return this.$store.state.auth.user;
    // },
  },
  beforeMount() {
    this.$store.commit("setSnackbarSuccess", {
      status: false,
    });
    this.$store.commit("setSnackbarError", {
      status: false,
    });
  },
  mounted() {
    // if (this.$store.state.context)
    //   this.$store.dispatch(this.$store.state.context + "/getData", {
    //     callbackSuccess: (data) => {
    //       // obtem o cliente vinculado com o qrcode, se existir.
    //       if (data.cliente) {
    //         this.$store.commit("cliente/setData", data.cliente);
    //       }
    //     },
    //     callbackError: (err) => {
    //       this.$store.commit("setSnackbarLoading", {
    //         status: false,
    //       });
    //       this.$store.commit("setSnackbarError", {
    //         message:
    //           err && [401, 403].includes(err.status)
    //             ? "Sessão expirada"
    //             : "Erro inesperado tente novamente.",
    //         status: true,
    //       });
    //       this.revokeAuth(err);
    //     },
    //   });
  },
};
</script>
