<template>
  <div class="snackbar-messages">
    <v-snackbar
      app
      :top="$vuetify.breakpoint.mdAndUp"
      class="mb-3"
      v-model="store.snackbarLoading.status"
      color="blue darken-1"
    >
      <v-progress-circular indeterminate class="mr-1" />
      {{ store.snackbarLoading.message }}
    </v-snackbar>

    <v-snackbar
      app
      :top="$vuetify.breakpoint.mdAndUp"
      class="mb-3"
      v-model="store.snackbarSuccess.status"
      color="success"
      timeout="3000"
    >
      <v-icon class="mr-1 mb-1" small>mdi-check-circle</v-icon>
      {{ store.snackbarSuccess.message }}
    </v-snackbar>

    <v-snackbar
      app
      :top="$vuetify.breakpoint.mdAndUp"
      class="mb-3"
      v-model="store.snackbarError.status"
      color="red"
      timeout="3000"
    >
      <v-icon class="mr-1 mb-1" small>mdi-alert-circle</v-icon>
      {{ store.snackbarError.message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  computed: {
    store() {
      return this.$store.state;
    },
  },
};
</script>

<style scoped></style>
