import Vue from "vue";
import VueRouter from "vue-router";
import LayoutDefault from "../layouts/default";
import LayoutExtended from "../layouts/extended";
import login from "@/views/login";
// import cadastro from "@/views/cadastro";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: LayoutDefault,
    children: [
      {
        name: "Home",
        path: "",
        component: () => import("../views/home/gerador-qrcodes"),
      },
      {
        name: "Home",
        path: "reset-user",
        component: () => import("../views/reset-user"),
      },
      {
        path: "",
        component: LayoutExtended,
        children: [
          {
            name: "RenderizarQRCode",
            path: "visualizar-qrcode",
            component: () => import("../views/home/renderizar-qrcode"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    component: LayoutExtended,
    children: [{ name: "Login", path: "", component: login }],
  },
  // {
  //   path: "/cadastro",
  //   component: LayoutExtended,
  //   children: [{ path: "", component: cadastro }],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
//   component: () =>
//     import(/* webpackChunkName: "about" */ "../views/About.vue"),
// },
