<template>
  <div class="footer">
    <h6 class="caption grey--text">
      &copy; {{ `2017 - ${new Date().getFullYear()}` }}
    </h6>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
