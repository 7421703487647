import api, { authHeader } from "@/store/api";

const auth = {
  namespaced: true,
  state: () => ({
    user: {},
  }),
  mutations: {
    setUser: (state, payload) => (state.user = payload),
  },
  actions: {
    register(
      { commit },
      { uid, email, password, terms, accept, tipo, session }
    ) {
      const headers = {
        SESSION: session,
      };
      return new Promise((resolve) => {
        api
          .post(
            `api/user`,
            {
              uid,
              email,
              password,
              termoPrivacidade: terms,
              aceite: accept,
              tipo,
            },
            { headers }
          )
          .then((result) => {
            commit("setUser", { uid, email });
            resolve(result);
          })
          .catch((err) => {
            console.error(err);
            resolve(err.response);
          });
      });
    },
    logout({ commit }) {
      commit("setUser", {});
    },
    login({ commit }, { email, password }) {
      return new Promise((resolve) => {
        api
          .post("api/auth/admin", { email, password })
          .then((result) => {
            commit("setUser", {
              id: result.data.content.user.uid,
              email,
              token: result.data.content.jwt,
              tipo: result.data.content.user.tipo,
            });
            resolve(result);
          })
          .catch((err) => {
            console.error(err);
            resolve(err.response);
          });
      });
    },
    check({ commit, state }) {
      return new Promise((resolve) => {
        api
          .post("api/auth/check", {}, { headers: authHeader(state.user.token) })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.error(err);
            resolve(err.response);
            commit("setUser", {});
          });
      });
    },
  },
};

export default auth;
