<template>
  <div class="login bg-primary fill-height">
    <v-layout align-center justify-center fill-height>
      <login-form
        :on-request="signIn"
        :on-response="response"
      />
    </v-layout>
  </div>
</template>

<script>
import LoginForm from "@/components/forms/login-form";
export default {
  metaInfo: {
    titleTemplate: "Login | %s",
  },
  components: { LoginForm },
  computed: {
  },
  mounted() {
    this.$store.dispatch("auth/check").then(() => {
      if (this.$store.state.auth.user && this.$store.state.auth.user.token) {
        this.$router.push("/");
      }
    });
  },
  methods: {
    async signIn({ email, password }) {
      return await this.$store.dispatch("auth/login", {
        email,
        password,
      });
    },
    response(res, err) {
      if (!res || !res.status || res.status >= 500)
        return err("erro desconhecido");
      else if (!res || !res.status || res.status >= 400)
        return err("usuário ou senha inválidos, tente novamente");

      if (res.status === 200) {
        this.$store.commit("setContext", res.data.content.user.tipo);
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  &-card {
    min-height: 450px;
  }
}
</style>
