<template>
  <div class="layout-desktop fill-height">
    <v-layout align-start justify-center fill-height class="w-100 bg-primary">
      <v-row
        class="
          row-layout-container
          d-flex
          justify-start
          px-0
          pl-lg-15 pl-xl-0
          pr-xl-8
        "
        no-gutters
      >
        <v-col cols="12" class="header-container">
          <v-layout align-end justify-center fill-height class="">
            <v-row>
              <v-col cols="3"></v-col>
              <v-col></v-col>
              <v-col></v-col>
            </v-row>
          </v-layout>
        </v-col>
        <v-col cols="3" class="sidemenu-container">
          <v-layout align-start justify-end fill-height class="mx-3">
            <v-card class="rounded-lg fill-height">
              <side-menu :img-logo="imgLogo" />
            </v-card>
          </v-layout>
        </v-col>
        <v-col cols="8" class="main-contianer">
          <v-layout align-start justify-end fill-height class="">
            <v-main class="fill-height">
              <v-card class="rounded-lg fill-height pa-3 pt-2">
                <v-fade-transition mode="out-in">
                  <router-view />
                </v-fade-transition>
              </v-card>
            </v-main>
          </v-layout>
        </v-col>
        <!--        <v-col cols="2" class="help-info-container">-->
        <!--          <v-layout align-start justify-start fill-height class="mx-3">-->
        <!--            <v-card-->
        <!--              class="rounded-lg fill-height pa-3 container container&#45;&#45;fluid"-->
        <!--            >-->
        <!--              <v-row-->
        <!--                no-gutters-->
        <!--                class="d-flex justify-space-between fill-height"-->
        <!--              >-->
        <!--                <v-col cols="12">-->
        <!--                  <div class="overline d-flex align-center">-->
        <!--                    <v-icon size="14" color="blue lighten-3">-->
        <!--                      mdi-information-outline-->
        <!--                    </v-icon>-->
        <!--                    <span class="overline ml-1 grey&#45;&#45;text text&#45;&#45;darken-2">-->
        <!--                      Info-->
        <!--                    </span>-->
        <!--                  </div>-->
        <!--                  <v-divider />-->
        <!--                  <div-->
        <!--                    v-if="auth.user && auth.user.token"-->
        <!--                    class="caption mt-1 grey&#45;&#45;text text&#45;&#45;darken-2 font-italic"-->
        <!--                  >-->
        <!--                    No painel de ações clique em `editar` e preencha o-->
        <!--                    formulário para registrar suas informações.-->
        <!--                  </div>-->
        <!--                  <div-->
        <!--                    v-else-->
        <!--                    class="caption mt-1 grey&#45;&#45;text text&#45;&#45;darken-2 font-italic"-->
        <!--                  >-->
        <!--                    Para editar as informações faça o-->
        <!--                    <v-btn-->
        <!--                      small-->
        <!--                      elevation="0"-->
        <!--                      color="blue lighten-1"-->
        <!--                      text-->
        <!--                      class="px-0 py-1"-->
        <!--                      to="/login"-->
        <!--                    >-->
        <!--                      <span class="text-lowercase">login</span>-->
        <!--                      <v-icon size="11" class="ml-1">mdi-open-in-new</v-icon>-->
        <!--                    </v-btn>-->
        <!--                  </div>-->
        <!--                </v-col>-->
        <!--                <v-col v-if="auth.user && auth.user.token" cols="12">-->
        <!--                  <div class="overline d-flex align-center">-->
        <!--                    <v-icon size="14" color="orange lighten-2">-->
        <!--                      mdi-arrow-right-drop-circle-outline-->
        <!--                    </v-icon>-->
        <!--                    <span class="overline ml-1 grey&#45;&#45;text text&#45;&#45;darken-2">-->
        <!--                      Ações-->
        <!--                    </span>-->
        <!--                  </div>-->
        <!--                  <v-divider />-->
        <!--                  <div-->
        <!--                    class="caption mt-1 grey&#45;&#45;text text&#45;&#45;darken-2 font-italic"-->
        <!--                  >-->
        <!--                    <div>-->
        <!--                      <v-btn-->
        <!--                        small-->
        <!--                        elevation="0"-->
        <!--                        color="blue lighten-1"-->
        <!--                        text-->
        <!--                        @click="onEdit"-->
        <!--                      >-->
        <!--                        <v-icon size="11" class="mr-1">mdi-pencil</v-icon>-->
        <!--                        <span class="text-lowercase">editar</span>-->
        <!--                      </v-btn>-->
        <!--                    </div>-->
        <!--                    <div-->
        <!--                      v-if="-->
        <!--                        ['Home', 'PetHome'].includes($route.name) &&-->
        <!--                        !$store.state.allFilled-->
        <!--                      "-->
        <!--                    >-->
        <!--                      <div class="d-flex align-center">-->
        <!--                        <v-switch-->
        <!--                          v-model="$store.state.hideNoFilled"-->
        <!--                          dense-->
        <!--                          inset-->
        <!--                        />-->
        <!--                        <small class="caption">-->
        <!--                          ocultar campos não preenchidos-->
        <!--                        </small>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </v-col>-->
        <!--              </v-row>-->
        <!--            </v-card>-->
        <!--          </v-layout>-->
        <!--        </v-col>-->
        <v-col cols="12" class="footer-container">
          <v-layout align-start justify-center fill-height class="mt-4">
            <v-row>
              <v-col cols="3"></v-col>
              <v-col class="d-flex justify-center">
                <footer-app />
              </v-col>
              <v-col cols="3"></v-col>
            </v-row>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import SideMenu from "@/components/menus/side-menu";
import FooterApp from "@/components/footers/footer";
export default {
  components: { FooterApp, SideMenu },
  props: {
    imgLogo: {
      type: String,
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
  },
  methods: {
    onEdit() {
      this.$store.commit("setShowModalDataForm", true);
      this.$nextTick(() => document.querySelector(".v-dialog").scrollTo(0, 0));
    },
  },
};
</script>

<style lang="scss">
@import "../assets/custom";

.row-layout-container {
  height: 600px !important;
  //background: #dd7070;
}

.header-container {
  height: 8px;
}

.sidemenu-container {
  //background: #dda370;
}

.main-contianer {
  //background: #ddc570;
}

.help-info-container {
  //background: #c0dd70;
}

.sidemenu-container,
.main-contianer,
.help-info-container {
  min-height: 500px;
}

.footer-container {
  //background: #d270dd;
  height: calc(100vh - (30px + 600px));
  //min-height: 0px;

  .footer {
    //background: #bc70dd;
  }
}
</style>
