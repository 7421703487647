<template>
  <div class="side-menu fill-height">
    <v-navigation-drawer permanent color="transparent">
      <v-list-item>
        <v-list-item-content class="pa-0 pb-2">
          <v-row no-gutters class="pt-2" align="center">
            <v-col v-if="$vuetify.breakpoint.lgAndUp">
              <v-list-item-title
                  class="text-h6 text-right d-flex justify-start"
              >
                <v-img
                    content-class="d-flex"
                    :src="getLogo(2) || $store.state.imgLogoDefault"
                    max-height="60"
                    max-width="80"
                    contain
                />
              </v-list-item-title>
            </v-col>
            <v-col>
              <v-list-item-subtitle class="text-sm-left text-lg-right">
                <div class="text-caption">Bem Vindo</div>
                <div>
                  <small>backoffice administrativo</small>
                </div>
              </v-list-item-subtitle>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndDown">
              <v-list-item-title class="text-h6 text-left d-flex justify-end">
                <v-img
                    content-class="d-flex"
                    :src="getLogo(2) || $store.state.imgLogoDefault"
                    max-height="60"
                    max-width="80"
                    contain
                />
              </v-list-item-title>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item class="pa-0 pl-2 pr-lg-2" style="max-width: 256px">
        <v-list-item-avatar
            v-if="$vuetify.breakpoint.mdAndDown"
            size="45"
            class="text-h6 text-right d-flex justify-start"
        >
          <v-img
              src="/img/person-avatar.png"
          />
        </v-list-item-avatar>
        <v-list-item class="pa-0">
          <v-list-item-content>
            <v-list-item-title
                class="text-subtitle-2 text-lg-right text-truncate"
                style="width: 1px"
            >
              Administrador
            </v-list-item-title>
            <v-list-item-subtitle
                class="caption text-lg-right text-truncate"
                style="width: 1px"
            >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-avatar
            v-if="$vuetify.breakpoint.lgAndUp"
            size="45"
            class="text-h6 text-right d-flex justify-end"
        >
          <v-img
              src="/img/person-avatar.png"
          />
        </v-list-item-avatar>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
            v-for="item in menu"
            :key="item.title"
            :to="item.to"
            exact
            link
            class="text-lg-right"
            @click="onClickMenuItem"
        >
          <v-list-item-icon
              v-if="$vuetify.breakpoint.mdAndDown"
              class="mr-sm-3"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="$vuetify.breakpoint.lgAndUp">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>

      <template #append>
        <div class="">
          <v-divider></v-divider>
          <div
              class="
              pa-2
              text-center text-lg-right
              d-flex
              justify-center justify-lg-space-between
              align-end
            "
          >
            <div class="d-none d-md-block">
              <v-img
                  content-class="d-flex"
                  :src="getLogo(1) || ''"
                  max-height="60"
                  max-width="80"
                  contain
              />
            </div>
            <div>
              <v-btn link elevation="0" small @click="onLogout">
                <span class="caption text-capitalize mr-2">Sair</span>
                <v-icon size="16" color="grey darken-2">mdi-export</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable prettier/prettier */
export default {
  props: {
    onClickMenuItem: {
      default: () => {},
      type: Function
    },
    imgLogo: {
      type: String,
    },
  },
  data: () => ({
    menu: [
      { title: "Gerar QR Codes", icon: "mdi-cog", to: "/" },
      { title: "Resetar Usuario QR Code", icon: "mdi-account-convert", to: "/reset-user" },
    ],
    right: null,
  }),
  computed: {
    store() {
      return this.$store.state;
    },
    getLogo() {
      return (num) => {
        return !this.imgLogo
            ? null
            : this.imgLogo + "/logo_" + num + ".jpg";
      };
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch('auth/logout');
      // this.$store.commit('pessoa/setData', {});
      // this.$store.commit('pet/setData', {});
      this.$router.push("/login")
    }
  }
};
</script>

<style lang="scss">
.side-menu {
  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  .theme--light.v-list-item--active:before,
  .theme--light.v-list-item--active:hover:before,
  .theme--light.v-list-item:focus:before {
    background-color: #dd7070;
  }

  .v-list .v-list-item--active,
  .v-list .v-list-item--active .v-icon {
    color: #e57373;
  }

  .theme--light.v-btn:hover {
    &:before {
      opacity: 0.12 !important;
      background-color: #dd7070;
    }

    .v-btn__content {
      color: #e57373 !important;

      .v-icon {
        color: #e57373 !important;
      }
    }
  }

  .v-btn .v-ripple__animation,
  .v-list .v-ripple__animation {
    background: #dd7070 !important;
  }

  .v-avatar {
    border: solid 1px #e5e5e5;
  }
}
</style>
