const mixin = {
  methods: {
    convertValue(value, props = {}) {
      let result;
      if (props.type === "boolean" && value) result = "Sim";
      else if (props.type === "boolean" && !value) result = "Não";
      else if (props.type === "decimal") {
        result = this.$options.filters.number(Number(value), "0.00", {
          decimalSeparator: ",",
          thousandsSeparator: ".",
        });
      } else if (props.type === "text-date") {
        result = !/\d\d\/\d\d\/\d\d\d\d/.test(value)
          ? value
            ? new Date(value)
                .toJSON()
                .split("T")[0]
                .split("-")
                .reverse()
                .join("/")
            : ""
          : value.split("/").reverse().join("-");
      } else if (props.type === "date") {
        result = !/\d\d\/\d\d\/\d\d\d\d/.test(value)
          ? value
            ? new Date(value)
                .toJSON()
                .split("T")[0]
                .split("/")
                .reverse()
                .join("-")
            : ""
          : value.split("/").reverse().join("-");
      } else {
        result = value;
      }

      return result;
    },
    revokeAuth(errResponse) {
      if (errResponse.response && errResponse.response.status === 403) {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      }
    },
  },
};

export default mixin;
