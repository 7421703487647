export const toPT_BRCapitalize = (value) => {
  return !value
    ? value
    : value
        .split(" ")
        .map(
          (w) =>
            ["da", "das", "de", "des", "di", "do", "dos"].includes(w)
              ? w
              : w.replace(/(^.)|(\s\w\.)|(\s([^e]))/g, (l) => l.toUpperCase())
          // : w.replace(/(^|\s)\S/g, (l) => l.toUpperCase())
        )
        .join(" ");
};
