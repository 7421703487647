<template>
  <div class="layout-extended">
    <v-app>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "../assets/custom";
</style>
