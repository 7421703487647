import vuetify from "@/plugins/vuetify";
import vueTheMask from "@/plugins/vue-the-mask";
import vueCookies from "@/plugins/vue-cookies";
import vueFragment from "@/plugins/vue-fragment";
import vue2Filters from "@/plugins/vue2-filters";
import vueMeta from "@/plugins/vue-meta";

export default {
  vuetify,
  vueTheMask,
  vueCookies,
  vueFragment,
  vue2Filters,
  vueMeta,
};
