import axios from "axios";

export const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.sigabrad.com.br/"
    : process.env.NODE_ENV === "homolog"
    ? "https://api.beta.sigabrad.com.br/"
    : process.env.NODE_ENV === "development"
    ? "http://localhost:8080/"
    : "";

export default axios.create({ baseURL });

export const authHeader = (token) => {
  return { Authorization: `Bearer ${token}` };
};
