<template>
  <div class="layout-mobile fill-height">
    <v-overlay :value="menuOpen" z-index="1" @click.native="onClickCloseMenu" />
    <v-app-bar
      v-if="$vuetify.breakpoint.xsOnly"
      color="white"
      elevation="0"
      fixed
      app
    >
      <v-app-bar-nav-icon class="pl-10">
        <v-img
          content-class="d-flex"
          :src="getLogo(1) || $store.state.imgLogoDefault"
          max-height="60"
          max-width="80"
          contain
        />
      </v-app-bar-nav-icon>
      <v-layout align-center justify-center>
        <v-toolbar-title>
          <v-img
            content-class="d-flex"
            :src="getLogo(2)"
            max-height="60"
            max-width="80"
            contain
          />
        </v-toolbar-title>
      </v-layout>
      <v-app-bar-nav-icon
        @click.stop="menuOpen = !menuOpen"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <v-layout
      align-start
      justify-center
      fill-height
      class="w-100 bg-primary pt-2 pt-sm-4"
    >
      <v-row
        class="row-layout-container d-flex justify-center px-2 px-sm-4"
        no-gutters
      >
        <v-col cols="12" sm="8" md="9" class="main-contianer">
          <v-layout align-start justify-end fill-height class="">
            <v-main class="fill-height pb-3">
              <v-card
                class="rounded-lg fill-height pa-3 pt-2"
                @touchstart="onTouchStartPage"
                :ripple="false"
              >
                <v-fade-transition mode="out-in">
                  <router-view />
                </v-fade-transition>
              </v-card>
              <div class="fab-container" :class="showFabButtons && 'show'">
                <v-layout column>
                  <v-btn
                    elevation="2"
                    fab
                    @click="onEdit"
                    color="blue"
                    text
                    class="white"
                    plain
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-dialog v-model="showDialogInfo">
                    <v-card>
                      <v-layout column align-center justify-center>
                        <v-card-text class="body-2">
                          Para editar as informações faça o login
                        </v-card-text>
                        <v-card-text class="d-flex justify-space-between">
                          <v-btn
                            small
                            color="primary"
                            @click="showDialogInfo = false"
                            to="/login"
                          >
                            Login
                            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                          </v-btn>
                          <v-btn
                            small
                            color="grey lighten-3"
                            @click="showDialogInfo = false"
                          >
                            fechar
                          </v-btn>
                        </v-card-text>
                      </v-layout>
                    </v-card>
                  </v-dialog>
                </v-layout>
              </div>
            </v-main>
          </v-layout>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="3"
          class="sidemenu-container mb-3"
          :class="menuOpen && 'menu-open'"
        >
          <!-- Button Close -->
          <!--          <div-->
          <!--            v-if="menuOpen"-->
          <!--            style="position: absolute; left: -30px; top: 5px; z-index: 1"-->
          <!--          >-->
          <!--            <v-btn elevation="2" fab x-small @click="onClickCloseMenu">-->
          <!--              <v-icon>mdi-close</v-icon>-->
          <!--            </v-btn>-->
          <!--          </div>-->

          <v-layout align-start justify-start fill-height class="ml-3">
            <v-card tile class="fill-height">
              <side-menu
                :on-click-menu-item="onClickMenuItem"
                :img-logo="imgLogo"
              />
            </v-card>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
  </div>
</template>

<script>
import SideMenu from "@/components/menus/side-menu";
export default {
  components: { SideMenu },
  props: {
    imgLogo: {
      type: String,
    },
  },
  data: () => ({
    menuOpen: false,
    showFabButtons: false,
    time: null,
    showDialogInfo: false,
  }),
  mounted() {
    setTimeout(() => {
      document.querySelector(".row-layout-container").scrollTo(0, -56);
      document.querySelector("html").scrollTo(0, 0);
    }, 300);
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    getLogo() {
      return (num) => {
        return !this.imgLogo ? null : this.imgLogo + "/logo_" + num + ".jpg";
      };
    },
  },
  methods: {
    onClickCloseMenu() {
      this.menuOpen = false;
    },
    onClickMenuItem() {
      this.onClickCloseMenu();
      this.$nextTick(() => {
        document.querySelector("html").scrollTo(0, 0);
        document
          .querySelector(".layout-mobile .row-layout-container")
          .scrollTo(0, 0);
      });
    },
    onEdit() {
      if (this.auth.user && this.auth.user.token) {
        this.$store.commit("setShowModalDataForm", true);
        this.$nextTick(() =>
          document.querySelector(".v-dialog").scrollTo(0, 0)
        );
      } else {
        this.showDialogInfo = true;
      }
    },
    onTouchStartPage() {
      // this.showFabControlls();
    },
    // showFabControlls() {
    //   this.showFabButtons = true;
    //   if (!this.time) {
    //     this.time = setTimeout(() => {
    //       this.showFabButtons = false;
    //       clearTimeout(this.time);
    //       this.time = null;
    //     }, 2500);
    //   }
    // },
  },
};
</script>

<style lang="scss">
@media (max-width: 1263px) {
  .layout-mobile {
    .sidemenu-container,
    .main-contianer {
      min-height: calc(100vh - 70px);
    }

    .fab-container {
      position: absolute;
      right: 22px;
      bottom: 22px;
    }

    .row-layout-container {
      height: calc(100vh - 0.5rem) !important;
      overflow: hidden auto;
    }
  }
}

@media (max-width: 600px) {
  .layout-mobile {
    //> .layout {
    //  height: calc(100vh - 56px);
    //}

    .fab-container {
      transform: translateX(140%);
      position: fixed;
      right: 22px;
      bottom: 22px;
      transition: transform 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

      &.show {
        transform: translateX(0%);
      }
    }

    .sidemenu-container,
    .main-contianer {
      min-height: calc(100vh - 76px);
    }

    .sidemenu-container {
      position: fixed !important;
      top: 0;
      right: 0;
      transform: translateX(100%);
      max-width: 256px;
      z-index: 5;
      transition: transform 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

      &.menu-open {
        transform: translateX(0%);
      }

      .v-navigation-drawer {
        height: 100vh !important;
      }
    }
  }
}
</style>
